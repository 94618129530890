import { Session, createClient } from "@supabase/supabase-js";
import { useCallback, useEffect, useState } from "react";
import { isDeployed } from "../config";

export const supabase = createClient(
  import.meta.env.VITE_SUPABASE_URL,
  import.meta.env.VITE_SUPABASE_KEY,
  {
    auth: {
      debug: !isDeployed,
      autoRefreshToken: true,
      persistSession: true,
    },
  },
);

export function useSupabaseAuth() {
  const [isLoading, setIsLoading] = useState(true);
  const [session, setSession] = useState<Session | null>(null);
  const [authError, setAuthError] = useState<Error | null>(null);

  function redirectToLogin() {
    window.location.assign("/login?reason=signed-out");
  }

  useEffect(() => {
    let mounted = true;

    async function getSession() {
      const { data, error } = await supabase.auth.getSession();

      // Avoid modifying state if the component is no longer mounted somehow:
      if (mounted) {
        if (error != null) {
          console.error(error, "Supabase auth error");
          setAuthError(error);
        } else {
          setSession(data.session);
        }

        setIsLoading(false);
      }
    }

    getSession();

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  /**
   * This should be unmounted on sign-out, but as a precaution we clear
   * out any relevant state:
   */
  const signOut = useCallback(() => {
    setIsLoading(false);
    setAuthError(null);

    if (session?.user != null) {
      supabase.auth.signOut().then(() => {
        setSession(null);
      });
    } else {
      redirectToLogin();
    }
  }, [session, setSession]);

  const sessionUser = session?.user;
  // Anything besides 'email' counts as a third-party provider, and enough to consider
  // the account as being complete:
  const hasThirdPartyProvider =
    sessionUser != null &&
    sessionUser.identities?.find((ident) => ident.provider !== "email") != null;

  // A user must set their password if they don't have anything besides their email login,
  // and have not set one yet:
  const mustSetPassword =
    sessionUser != null &&
    !hasThirdPartyProvider &&
    !sessionUser?.user_metadata?.hasSetPassword;

  return {
    isLoading,
    signOut,
    user: sessionUser ?? null,
    authError,
    isLoggedIn: sessionUser != null,
    jwtBearerToken: session == null ? null : `Bearer ${session.access_token}`,

    hasThirdPartyProvider,
    mustSetPassword,
    mustCompletePostSignup:
      sessionUser != null && !hasThirdPartyProvider && mustSetPassword,
  };
}
